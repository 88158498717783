<template>
  <Modal
    :activeModal="isActiveModal"
    @closeModal="closeModal"
    :widthModal="500"
  >

    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          Motivo do cancelamento
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <v-card
        class="white--text align-end pa-2"
      >
        <v-card-text class="modal-cadastro-form">
          <v-text-field
            v-model="reasonCancel"
            outlined
            clearable
            label="Descreva o motivo do cancelamento"
            type="text"
            dense
            persistent-hint
            :error-messages="errorMensagens"
          />
        </v-card-text>

        <v-card-actions class="modal-cadastro-footer">
          <div></div>
          <v-btn 
            :color="variables.colorPrimary" 
            @click="confirmarCancelamento()" 
            class="br-btn"
          >Confirmar cancelamento</v-btn>
        </v-card-actions>
        
      </v-card>
    </v-card>
  </Modal>
</template>

<script>
// eslint-disable-next-line
import { mapActions, mapGetters } from 'vuex'
// import Events from '@/core/service/events'
// eslint-disable-next-line
import { required } from 'vuelidate/lib/validators'
import variables from '@/assets/styles/helpers/_variables.scss'
// eslint-disable-next-line
import { clone, filter, size, cloneDeep } from 'lodash'
// eslint-disable-next-line
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
export default {
  name: 'ModalMotivoCancelamento',
  props: {
    isActiveModal: {
      type: Boolean,
      default: false
    },
    reasonCancelProps: {
      type: String,
      default: ''
    }
  },
  components:{
    Modal: () => import('@/views/components/modal')
  },
  data: () => ({
    activeModal: false,
    reasonCancel: ''
  }),

  mounted () {
    this.reasonCancel = this.reasonCancelProps
  },

  watch: {
    reasonCancelProp (val) {
      this.reasonCancel = val
    },

    reasonCancel (val) {
      this.$emit('update:reasonCancelProps', val)
    }
  },

  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('despacho', ['images']),
    variables: () => variables,

    errorMensagens () {
      let error = []
      if (!this.$v.reasonCancel) return []
      if (!this.$v.reasonCancel.$dirty) return []

      !this.$v.reasonCancel.required && error.push('Descrição é obrigatório')      
      return error
    },
  },
  methods: {
    ...mapActions('despacho', ['confirmationPayment']),

    closeModal () {
      this.$emit('closeModal', false)
    },
    confirmarCancelamento () {
      if (this.$v.reasonCancel.$invalid) { 
        this.$v.reasonCancel.$touch()
        return false 
      }

      // this.$emit('update:reasonCancel', this.reasonCancel)
      this.$emit('confirmarCancelamentooo')
    }
  },

  validations () {
    return  {
      reasonCancel: { required }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/components/modal-cadastro';
.modal-cadastro-form-col-input .auto {
  background: red;
}
.theme--light.v-input input, .theme--light.v-input textarea{
  padding: 2;
}
.theme--light.v-input{
  padding: 0;
  margin: 0;
}
#bug{
  margin-top: 7px !important
}
</style>
